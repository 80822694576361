import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
const Terms = ({ data, location }) => {
  return (
    <div>
      <Layout location={location} title='DXデイリーポスト'>
	  <SEO title="サイト利用規約" description="" />
        <h2 style={{fontSize: 18 + 'px'}}>サイト利用規約</h2>
        <h2 className="aboutus_title">弊社Webサイトのご利用にあたって</h2>
        <p>当Webサイトは、株式会社デジタルリサーチ（以下デジタルリサーチ）が運営しております。当Webサイトをご利用になる前に以下の利用規約をお読みいただき、これらの条件に同意された場合のみご利用ください。デジタルリサーチは、当Webサイトに掲示したコンテンツ (情報、データ、テキスト、画像、映像、音声等。以下「コンテンツ」) またはURLを予告なく変更・追加・削除することがあります。また、当Webサイトの運営を一時停止または中止することがあります。</p>
        <h2 className="aboutus_title">著作権について</h2>
        <p>当Webサイトを構成するコンテンツのうち、外部サイトから引用したコンテンツの著作権は引用元の企業、団体、個人に帰属します。それ以外のコンテンツの著作権はデジタルリサーチに帰属します。なお、私的使用、その他法律によって明示的に認められる範囲を超えて、コンテンツを使用（複製、改変、アップロード、掲示、送信、頒布、ライセンス、販売、出版、翻訳、転載、商品化などを含みます）することは、デジタルリサーチの文書による事前の許諾がない限り禁止します。</p>
        <h2 className="aboutus_title">リンクについて</h2>
        <p>デジタルリサーチは当Webサイトから外部のWebサイトへのリンクを提供し、またその外部サイトが他のサイトにリンクを行うことを許可する場合があります。これはお客様の利便性を考えてのリンク提供であり、外部のWebサイトの内容についてデジタルリサーチが保証するものではありません。</p>
        <h2 className="aboutus_title">損害賠償</h2>
        <p>お客さまが法令または本利用規約に違反して、デジタルリサーチに損害を与えた場合、デジタルリサーチはお客さまに対して損害賠償請求を行うことができるものとします。</p>
        <h2 className="aboutus_title">適用法</h2>
        <p>当Webサイトを利用することにより、お客様は本件契約が抵触法の規定に拘わらず、日本法によって解釈され適用されることに同意するものとします。当Webサイトを利用することにより、お客様は本件契約に関連または由来するすべての紛争が、東京地方裁判所または東京簡易裁判所を第一審専属管轄裁判所管轄に服することに同意するものとします。</p>
        <h2 className="aboutus_title">利用規約の変更</h2>
        <p>デジタルリサーチは当Webサイトの利用規約を変更する場合があります。その際には改訂した規約を掲載するものとします。</p>
      </Layout>
    </div>
  )
}
export default Terms

